var page_loaded = 1;
global.page_loaded = page_loaded;

global.jQuery = require('jquery');
var slickInit = require('slick-carousel'),
	macy = require('macy');

page = {
	load: function(){
		this.wineSearchAnim();
		this.vineyardAnim();
		this.catMapAnim();
		this.winesCarousel();
		this.newsMasonry();
	},
	wineSearchAnim: function(){
	    var controller = new smInit.Controller(),
			tl = new tmInit(),
			sheetText = $('.wineSheet__text > *'),
			wineImg = $('.wineSheet__imgWrap img'),
			wineSheet = $('.wineSheet__sheet > *'),
			tween = tl.staggerTo(sheetText , .3, {top:0, opacity:1}, .15)
					  .to(wineImg , .8, {bottom:0}, "-=.4")
					  .staggerTo(wineSheet , .2, {top:0, opacity:1}, .1, "-=.5")

			scene = new smInit.Scene({reverse: true, triggerElement: $('#wineSearch__trig')[0]}).setTween(tween).addTo(controller);
	},
	vineyardAnim: function(){
	    var controller = new smInit.Controller(),
			tl = new tmInit(),
			catIntroText = $('.catIntro .container > *'),
			tween = tl.staggerTo(catIntroText, .2, {top:0, opacity:1}, .15),

			scene = new smInit.Scene({reverse: true, triggerElement: $('#catIntro__trig')[0]}).setTween(tween).addTo(controller);
	},
	catMapAnim: function(){
	    var controller = new smInit.Controller(),
			tl = new tmInit(),
			catMap = $('.catIntro__map'),
			tween = tl.to(catMap, .5, {left:0, opacity:1}),

			scene = new smInit.Scene({reverse: true, triggerElement: $('#catIntro__mapTrig')[0]}).setTween(tween).addTo(controller);
	},
	winesCarousel: function(){
		jQuery('.marchiWine__gal').slick({
			slidesToShow: 5,
			slidesToScroll: 1,
			arrows: true,
			speed: 450,
			dots: true,
			touch: false,
			infinite: false,
		  	nextArrow: '<div class="slick-next"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 8.9 15.3" style="enable-background:new 0 0 8.9 15.3;" xml:space="preserve" width="8.9" height="15.3"><polygon points="1.3,0 0,1.3 6.4,7.6 0,14 1.3,15.3 8.9,7.6 "/></svg></div>',
			prevArrow: '<div class="slick-prev"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 8.9 15.3" style="enable-background:new 0 0 8.9 15.3;" xml:space="preserve" width="8.9" height="15.3"><polygon points="7.7,15.3 8.9,14 2.5,7.6 8.9,1.3 7.7,0 0,7.6 "/></svg></div>',
			responsive: [
			    {
			      breakpoint: 1500,
			      settings: {
			        slidesToShow: 4
			      }
			    },
			    {
			      breakpoint: 1199,
			      settings: {
			        slidesToShow: 3
			      }
			    },
			    {
			      breakpoint: 991,
			      settings: {
			        slidesToShow: 2
			      }
			    },
			    {
			      breakpoint: 600,
			      settings: {
			        slidesToShow: 1
			      }
			    },
			]
		});
	},
	newsMasonry: function(){
		if($('#macy-container'.length)){
			var macyInstance = macy({
				container: '#macy-container',
				columns: 4,
				margin: 10,
				trueOrder: true,
				breakAt: {
			        1600: 3,
			        991: 2,
			        420: 1
			    }
			});
		}
	}
}


$(window).on('load', function(){
	page.load();
})
